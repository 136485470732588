<template>
  <ScreenWidthProvider v-slot="{ isWider }">
    <NuxtLink
      v-if="isWider(1860)"
      class="card flex flex-row text-black"
      :to="formattedLink"
      tag="a"
    >
      <div class="flex justify-center items-center px-0 bg-white-blue">
        <div class="date text-center mb-0">
          <div class="formatted-month">
            {{ formattedMonth }}
          </div>
          <div class="formatted-day">
            {{ formattedDay }}
          </div>
        </div>
      </div>
      <div class="card-img">
        <ResponsiveImage
          class="size-full"
          :image="image"
          :alt="name"
          fit="cover"
        />
      </div>
      <div class="card-body py-6 px-6">
        <div class="h5 card-title mb-0">
          {{ name }}
        </div>
        <div class="card-price">
          <span>
            fr.
          </span>
          <span class="strikethrough">
            {{ $n(originalPrice) }}
          </span>
          <span class="card-price-current ml-2">
            {{ $n(price) }}
          </span>
        </div>
        <p
          class="text-blue font-bold cta"
          data-i18n="startpageCustomCampaign_CTA"
        >
          {{ $t('startpageCustomCampaign_CTA') + ' >' }}
        </p>
      </div>
    </NuxtLink>
    <NuxtLink
      v-else
      class="mobile-card flex flex-col text-black relative"
      :to="formattedLink"
      tag="a"
    >
      <div class="flex justify-center items-center px-0 bg-white-blue">
        <div class="date text-center mb-0">
          <span class="formatted-day mr-2">
            {{ formattedDay }}
          </span>
          <span class="formatted-month">
            {{ formattedMonth }}
          </span>
        </div>
      </div>
      <div class="card-img-responsive">
        <ResponsiveImage
          class="size-full"
          :image="image"
          :alt="name"
          fit="cover"
        />
      </div>
      <div class="card-body py-6 px-6">
        <div class="h5 card-title mb-0">
          {{ name }}
        </div>
        <div class="card-price">
          <span>
            fr.
          </span>
          <span class="strikethrough">
            {{ $n(originalPrice) }}
          </span>
          <span class="card-price-current ml-2">
            {{ $n(price) }}
          </span>
        </div>
        <p
          class="text-blue font-bold cta"
          data-i18n="startpageCustomCampaign_CTA"
        >
          {{ $t('startpageCustomCampaign_CTA') + ' >' }}
        </p>
      </div>
    </NuxtLink>
  </ScreenWidthProvider>
</template>

<script setup>
const { localeDateCustomFormats } = useDate()
const dayjs = useDayjs()

const props = defineProps({
  date: {
    type: String,
    default: '',
  },

  image: {
    type: String,
    default: '',
  },

  name: {
    type: String,
    default: '',
  },

  originalPrice: {
    type: Number,
    default: 0,
  },

  price: {
    type: Number,
    default: 0,
  },

  link: {
    type: String,
    default: '',
  },
})
const formattedLink = computed(() => {
  if (props.link.startsWith('/')) {
    return props.link
  }
  return `/${props.link}`
})

const formattedMonth = computed(() => {
  return dayjs(props.date).format(localeDateCustomFormats.value.customDateMonth)
})

const formattedDay = computed(() => {
  return dayjs(props.date).format(localeDateCustomFormats.value.customDateDay)
})
</script>

<style lang="scss" scoped>
.strikethrough {
  &:before {
    border-top: 1px solid !important;
    }
  }
.card {
  //TODO: change to real values 100% when image is available
  width: 566px;
  height: 148px;
  position: relative;
  box-shadow: 5px 5px 15px #00000026;

  .card-img {
    width: 189px;
    height: 148px;
  }
  .card-body{
    max-width: 266px;
  }
}
.card-title {
  font-size: 1rem;
}
.cta {
    position: absolute;
    bottom: 0;
    right: 1.5rem;
  }
.mobile-card {
  height: 100%;
  width: auto;
  box-shadow: 5px 5px 15px #00000026;
}
.date {
  text-transform: uppercase;
  width: 111px;

  .formatted-month{
    font-family: 'roboto', sans-serif;
    font-weight: normal;
    letter-spacing: 0.5px;
    font-size: 25px;
    line-height: calc(25/50);
    color: theme('colors.medium-blue');
  }
  .formatted-day{
    font-family: 'Libre Baskerville', sans-serif;
    font-weight: bold;
    letter-spacing: 0.8px;
    font-size: 40px;
    color: theme('colors.medium-blue');
  }
}
.card-img-responsive {
    width: auto;
    max-height: 300px;
  }
.card-price {
  .card-price-current{
    font-size: 25px;
    font-family: 'roboto', sans-serif;
    font-weight: 900;
    color: #F72A2A;
  }
}
@media(max-width: 718px){
  .mobile-card {
    max-width: 100%;
  }
  .card-img-responsive {
    max-width: unset;
    width: 100%;
    height: 100%;
    max-height: 300px;
  }
}
</style>