<template>
  <div>
    <div
      v-if="trips && trips.departures && trips.departures.length > 0"
      class="flex flex-col w-full items-center startpage-custom-campaign relative mb-12 pb-4 md:pb-4"
    >
      <img
        class="w-full mb-6 crop-right-increase-height cursor-pointer"
        :alt="$t('startpageCustomCampaign_title')"
        :src="$t('startpageCustomCampaign_image')"
        data-i18n="startpageCustomCampaign_image"
        background-color="white"
        fit="contain"
        @click="goToEventsPage"
      />
      <TranslationTool>
        <div
          class="ml-auto -mt-3 mb-12"
          data-i18n="startpageCustomCampaign_banner-link"
        >
          <fa
            icon="link"
            size="xl"
          />
        </div>
      </TranslationTool>
      <div
        class="subtitle font-bold flex flex-col size-full items-center justify-center text-align-center"
        data-i18n="startpageCustomCampaign_title"
      >
        {{ $t('startpageCustomCampaign_title') }}
      </div>
      <div class="subtext whitespace-pre-line mb-4 font-semibold">
        <div class="inline-flex flex-row items-center mb-4 mt-4 sm:mt-0">
          <hr />
          <span
            class="mx-4 text-center"
            data-i18n="startpageCustomCampaign_subtitle"
          >{{ $t('startpageCustomCampaign_subtitle') }}</span>
          <hr />
        </div>
      </div>
      <p
        class="text-align-center mb-12 whitespace-pre-line"
        data-i18n="startpageCustomCampaign_ingress"
      >
        {{ $t('startpageCustomCampaign_ingress') }}
      </p>
      <Swiper
        ref="swiper"
        class="w-full"
        dots-type="dark"
        rewind
        only-render-visible-slides
        prevent-swiper-update
        constant-display-navigation
        navigation-position="bottom"
        data-i18n="startpageCustomCampaign_calendar-endpoint"
      >
        <div
          v-for="(pageTrips, idx) in paginatedTrips"
          :key="idx"
          class="grid-container"
        >
          <RolfsCustomCampaignCard
            v-for="(trip, idc) in pageTrips"
            :key="idc"
            class="grid-item"
            :date="trip.date"
            :image="trip.main_picture_cloudinary"
            :name="trip.tripname"
            :original-price="trip.original_price_SEK"
            :price="trip.price_SEK"
            :link="trip.triptypeurl"
          />
        </div>
        <template #navigation="{ slideNext, slidePrev, activeSlideIndex, showSlideIndex }">
          <div class="pagination mt-12">
            <button
              v-for="(page, index) in numberOfPages"
              :key="index"
              class="bullet"
              :aria-label="$t('swiperBulletLabel', { n: index + 1 })"
              :class="{ active: activeSlideIndex === index }"
              @click="showSlideIndex(index)"
            />
          </div>
          <div class="nav-buttons w-full absolute">
            <button
              v-if="activeSlideIndex > 0"
              class="prev-button text-white rounded-full p-0 z-[3] flex justify-center items-center"
              :aria-label="$t('nextButton')"
              @click="slidePrev"
            >
              <FaSharpSolidChevronLeft
                class="small-chevron block"
                height="18"
                width="18"
                fill="white"
              />
              <FaSharpRegularChevronLeft
                class="big-chevron block"
                height="25"
                width="25"
                fill="#b8bfcc"
              />
            </button>
            <button
              v-if="activeSlideIndex < numberOfPages - 1"
              class="next-button text-white rounded-full p-0 z-[3] flex justify-center items-center"
              :aria-label="$t('goBack')"
              @click="slideNext"
            >
              <FaSharpSolidChevronRight
                class="small-chevron block"
                height="18"
                width="18"
                fill="white"
              />
              <FaSharpRegularChevronRight
                class="big-chevron block"
                height="25"
                width="25"
                fill="#b8bfcc"
              />
            </button>
          </div>
        </template>
      </Swiper>
    </div>
  </div>
</template>

<script setup>
import FaSharpSolidChevronLeft from '@fortawesome/fontawesome-pro/svgs/sharp-solid/chevron-left.svg'
import FaSharpSolidChevronRight from '@fortawesome/fontawesome-pro/svgs/sharp-solid/chevron-right.svg'
import FaSharpRegularChevronRight from '@fortawesome/fontawesome-pro/svgs/sharp-regular/chevron-right.svg'
import FaSharpRegularChevronLeft from '@fortawesome/fontawesome-pro/svgs/sharp-regular/chevron-left.svg'

const ITEMS_PER_PAGE = ref(8)
const calendarStore = useCalendarStore()

const { t } = useI18n()

const { $sentryCaptureException } = useNuxtApp()

const { error, data } = await useAsyncData(async () => {
  let trips

  try {
    const { data: response } = await apiFetch(
      t('startpageCustomCampaign_calendar-endpoint')
    )

    if (response.departures) {
      trips = response
    } else if (response.id) {
      calendarStore.SET_CAMPAIGN_ID(response.id)
      calendarStore.SET_LASTMINUTE(true)
      calendarStore.SET_START_PAGE_CAMPAIGN(true)

      trips = await calendarStore.getCalendar()
    }

    trips.departures = trips.departures.reduce((uniqueTrips, trip) => {
      const otherTripTypeDepartureIndex = uniqueTrips
        .findIndex((uniqueTrip) => uniqueTrip.triptypeid === trip.triptypeid)

      if (otherTripTypeDepartureIndex !== -1) {
        if (uniqueTrips[otherTripTypeDepartureIndex].price_SEK > trip.price_SEK) {
          uniqueTrips.splice(otherTripTypeDepartureIndex, 1)
        } else {
          return uniqueTrips
        }
      }

      return [
        ...uniqueTrips,
        trip,
      ]
    }, [])
  } catch (e) {
    $sentryCaptureException(e)
  }

  return {
    trips: trips ?? { departures: [] },
  }
})

if (error.value) {
  throw createError({
    message: error.value.message,
    statusCode: 404,
    stack: error.value.stack,
    fatal: true,
  })
}

const { trips } = data.value

const setItemsPerPage = () => {
  if (window?.innerWidth <= 718) {
    ITEMS_PER_PAGE.value = 1
  } else if (window?.innerWidth <= 1860) {
    ITEMS_PER_PAGE.value = 4
  } else {
    ITEMS_PER_PAGE.value = 8
  }
}

onMounted(() => {
  window.addEventListener('resize', setItemsPerPage)
  setItemsPerPage()
})

const paginatedTrips = computed(() => {
  return (trips && trips.departures)
    ? chunkArray(trips.departures, ITEMS_PER_PAGE.value)
    : []
})

const numberOfPages = computed(() =>
  (trips && trips.departures)
    ? Math.ceil(trips.departures.length / ITEMS_PER_PAGE.value)
    : 0
)

onBeforeUnmount(() => {
  window.removeEventListener('resize', setItemsPerPage)
  calendarStore.SET_CAMPAIGN_ID(null)
  calendarStore.SET_FORCE_AIRPORT(null)
  calendarStore.SET_LASTMINUTE(false)
  calendarStore.SET_START_PAGE_CAMPAIGN(false)
})

const goToEventsPage = () => {
  navigateTo(
    t('startpageCustomCampaign_banner-link')
  )
}
</script>

<style lang="scss" scoped>
.startpage-custom-campaign {
  // .small-banner {
  //   height: 86px;
  // }

  h3, h5, .subtext, .subtitle {
    font-family: libre baskerville, serif;
  }

  .subtitle {
    letter-spacing: 0.7px;
    font-size: 2rem;
  }

  p {
    letter-spacing: 0.34px;
  }

  hr {
    width: 50px;
    background: black;
  }
}
.subtext {
  font-size: 1rem;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  justify-items: center;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.flex-gap {
  gap: 1.2em;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1em;

  .bullet {
    margin: 0 0.5em;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &.active {
      background: black;
    }
  }
}
.nav-buttons {
  bottom: 2rem;

  button {
    width: 48px;
    height: 48px;
    background: theme('colors.medium-blue');
  }
  .prev-button {
    position: absolute;
    left: 0;

    &:hover {
      .small-chevron {
        fill: theme('colors.gray.DEFAULT');
      }
    }
  }

  .next-button {
    position: absolute;
    right: 0;

    &:hover {
      .small-chevron {
        fill: theme('colors.gray.DEFAULT');
      }
    }
  }
}

.small-chevron{
  display: block !important;
}

.big-chevron {
  display: none !important;
}

@media (max-width: 718px) {
  .pagination {
    display: none;
  }

  .nav-buttons{
    bottom: 0;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    justify-items: center;
    margin-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 580px) {
  .crop-right-increase-height {
    object-fit: cover;
    object-position: center;
    height: 48px;
  }

  .startpage-custom-campaign {
    hr {
      width: 25px;
      background: black;
    }
  }

  .nav-buttons {
    top: calc(50% - 48px);

    button {
      background: theme('colors.white');
    }

    .next-button {
      right: -24px;

      &:hover {
        .big-chevron {
          fill: black;
        }
      }
    }

    .prev-button {
      left: -24px;

      &:hover {
        .big-chevron {
          fill: black;
        }
      }
    }
  }

  .big-chevron {
    display: block !important;
  }

  .small-chevron{
    display: none !important;
  }
}
</style>